import React, { useEffect } from "react";
import styles from "../GetStarted.module.scss";
import GetStartedCard from "../../../components/GetStartedCard/GetStartedCard.component";
import { Link } from "react-router-dom";
// import { ReactComponent as OfferLetterIcon } from "../../../svg/icons/offer-letter-icon.svg";
import { ReactComponent as OnboardIcon } from "../../../svg/icons/onboard-icon.svg";
import ROUTES from "../../../helpers/ROUTES";
import { useAuth } from "../../../Context/auth.context";
import { appInsights } from "../../../components/AppInsight/AppInsight";

export default function GetStarted() {
  const { eligibilityData } = useAuth();
  const disableGetStarted =
    !eligibilityData ||
    (eligibilityData &&
      (eligibilityData.statusCode > 5 && eligibilityData.statusCode <= 8
        ? true
        : eligibilityData.subsequentStatusCode > 5 &&
          eligibilityData.subsequentStatusCode <= 8
        ? true
        : false));
  // const disableViewOffer =
  //   !eligibilityData ||
  //   (eligibilityData &&
  //     (eligibilityData.statusCode >= 0 && eligibilityData.statusCode <= 5
  //       ? true
  //       : eligibilityData.subsequentStatusCode >= 1 &&
  //         eligibilityData.subsequentStatusCode <= 5
  //       ? true
  //       : false));

  useEffect(() => {
    appInsights.trackPageView({
      name: "GetStarted.component.jsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <div className={`${styles.getStartedOuterLayout} `}>
      {/* Don't remove this empty div */}
      <div></div>
      <div className={`${styles.getStartedInnerLayout} ${styles.tiny}`}>
        {!disableGetStarted && (
          <h3 className="page-title mb-4 text-center">Welcome to Advancly!</h3>
        )}
        {disableGetStarted && (
          <h3 className="page-title mb-4 text-center">Your offer is ready!</h3>
        )}
        {!disableGetStarted && (
          <p className="text-center">
            To onboard your business and start accessing funds, kindly <br />{" "}
            complete the tasks below. When you’re done, please click
            <br /> the “Request Onboarding” button
          </p>
        )}
        {!disableGetStarted && (
          <p className="text-center mb-4">
            Click on the “Onboard My Business” card below to get started.
          </p>
        )}
        {disableGetStarted && (
          <p className="text-center mb-4">
            Congratulations! Your product request has been approved. Click view
            offer to proceed with your request.
          </p>
        )}
        <div
          className={`${styles.getStartedCardsLayout} ${
            disableGetStarted ? styles.getStartedOnboardBusiness : ""
          }`}
        >
          {!disableGetStarted && (
            <GetStartedCard
              title="Onboard My Business"
              text="Answer a few questions to let us know more about your business"
              button={
                <Link
                  to={ROUTES.GET_STARTED_ONBOARDING}
                  type="button"
                  className={`btn ${
                    disableGetStarted
                      ? "advancly-grey-btn disabled"
                      : "advancly-btn transition-3d-hover"
                  }  btn-sm  `}
                  onClick={(e) => {
                    if (disableGetStarted) e.preventDefault();
                  }}
                >
                  {eligibilityData.statusCode > 0 ? "Continue" : " Get Started"}
                </Link>
              }
              icon={<OnboardIcon />}
            />
          )}
          {/* <GetStartedCard
            title="Offer Letter & SLA"
            text="Download, sign and upload our offer letter to Go Live"
            button={
              <Link
                to={ROUTES.GET_STARTED_OFFER}
                type="button"
                className={`btn ${
                  disableViewOffer
                    ? "advancly-btn disabled"
                    : "advancly-btn transition-3d-hover"
                }  btn-sm`}
                onClick={(e) => {
                  if (disableViewOffer) e.preventDefault();
                }}
              >
                View Offer
              </Link>
            }
            icon={<OfferLetterIcon />}
          /> */}
        </div>
      </div>
    </div>
  );
}
