import React, { useState } from "react";
import { useQueryCache } from "react-query";
import styles from "./ReUpload.module.scss";
import { ReactComponent as AdvanclyIcon } from "../../../../svg/icons/advancly-3d-icon-1.svg";
import { ReactComponent as CloseModal } from "../../../../svg/icons/modal-close.svg";
import Loader from "../../../../components/Loader/Loader.component";
import { errorHandler } from "../../../../helpers/errorHandler";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { appInsights } from "../../../../components/AppInsight/AppInsight";
import { postData } from "../../../../apis/apiMethods";

const ReUploadDocumentConfirmation = ({
  setShowModal,
  setAggregatorDocumentId,
  setCurrentFieldDocumentId,
  currentFieldDocumentId,
  documentSelectedReuploadFile,
  aggregatorDocumentId,
  setDocumentSelectedReuploadFile,
}: any) => {
  const queryCache = useQueryCache();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const hideModal = () => {
    setShowModal(false);
  };
  const onSubmitRepload = async () => {
    const formData = new FormData();

    // Append data from aggregator_document to FormData
    formData.append(
      "AggregatorDocuments[0].DocumentFieldId",
      currentFieldDocumentId
    );
    formData.append("AggregatorDocuments[0].DocumentTypeCode", "");
    formData.append("AggregatorDocuments[0].Type", "");
    formData.append(
      "AggregatorDocuments[0].AggregatorDocumentId",
      aggregatorDocumentId
    );

    // Append files to FormData

    formData.append(
      `AggregatorDocuments[0].Files`,
      documentSelectedReuploadFile
    );

    try {
      let res = await postData(
        apiEndpoints.reuploadCategoryFile,
        formData,
        true
      );

      setLoading(false);
      setSuccess(res?.message);
      queryCache.invalidateQueries("getListOfDocumentsAlreadyUploaded");
      setAggregatorDocumentId(null);
      setCurrentFieldDocumentId(null);
      setTimeout(() => {
        setSuccess(null);
        hideModal();
      }, 3000);
      setDocumentSelectedReuploadFile(null);
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: {
          fileName: "ReUploadDocumentConfirmation.tsx",
        },
      });

      setLoading(false);
      setSuccess(null);
      setError(errorHandler(error));

      setTimeout(() => {
        setError(null);
        hideModal();
      }, 5000);
    }
  };
  return (
    <div
      className="modal show d-block fade"
      // id="reUploadDocumentConfirmationModal"
      // tabIndex={-1}
      role="dialog"
      aria-labelledby="reUploadDocumentConfirmationModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header border-bottom-0">
            <button
              type="button"
              className="close "
              // data-dismiss="modal"
              // aria-label="Close"
              // ref={dismissRef}
              onClick={hideModal}
            >
              {" "}
              <CloseModal />
            </button>
          </div>
          <div className="modal-body">
            {error && <p className="alert alert-danger small">{error}</p>}
            {success && <p className="alert alert-success small">{success}</p>}
            <div
              className={`d-flex justify-content-center ${styles.advanclyIconDialog}`}
            >
              <AdvanclyIcon />
            </div>
            <h5
              className={`page-subtitle text-center w-100 ${styles.confirmationHeader}`}
              id="reUploadDocumentConfirmationModal"
            >
              Are you sure you want to reupload{" "}
            </h5>
            <div className="">
              <button
                className={`btn btn-sm advancly-white-btn transition-3d-hover w-100 ${styles.updateUserConfirmationCancelButton}`}
                onClick={hideModal}
              >
                Cancel
              </button>

              <button
                className="btn btn-sm advancly-btn transition-3d-hover w-100"
                disabled={loading}
                onClick={onSubmitRepload}
              >
                Proceed {loading && <Loader />}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReUploadDocumentConfirmation;
